/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'server/i18n';
import cx from 'classnames';

import InputTextWithLoader from 'components/common/inputs/text-with-loader';
import MagicLinkForm from './form';

import css from './style.css';

const FIELDS_VALIDATE = [
  'email',
];

const DEBOUNCE_TIMEOUT = 2000;

@withTranslation('common')
@inject('mobxStore')
@observer
class MagicLinkFormLayout extends Component {
  @observable isFieldTyping = null;

  constructor(props) {
    super(props);
    this.form = new MagicLinkForm();

    FIELDS_VALIDATE.forEach((fieldName) => {
      this.form.observe({
        path: fieldName,
        key: 'value',
        call: () => {
          this.fieldDebounce(() => {
            this.form.$(fieldName).validate({ showErrors: true });
          }, DEBOUNCE_TIMEOUT);
        },
      });
    });
  }

  componentDidMount() {
    const {
      mobxStore: {
        magicLinkStore,
      },
    } = this.props;
    magicLinkStore.resetStatus();
  }

  onSuccess(form) {
    const values = form.values();
    this.props.mobxStore.magicLinkStore.postMagicLink(values.email, this.props.redirectUrl);
  }

  fieldDebounce = (fn, wait) => {
    clearTimeout(this.isFieldTyping);
    this.isFieldTyping = setTimeout(fn, wait);
  }

  handleEmailFormChange() {
    if (this.props.mobxStore.magicLinkStore.status !== 0) {
      this.props.mobxStore.magicLinkStore.resetStatus();
    }
  }

  render() {
    const { form } = this;
    const {
      t,
      mobxStore: {
        magicLinkStore,
      },
      defaultTitleOverride,
      subTitle,
    } = this.props;

    if (magicLinkStore.sent && magicLinkStore.status === 200 && !form.$('email').error) {
      this.form.$('email').set('label', t('common:auth.sent-label', 'Email sent'));
    } else {
      this.form.$('email').set('label', t('common:auth.email-label', 'Verify your email'));
    }

    return (
      <form
        id="login"
        onSubmit={form.onSubmit}
        onChange={this.handleEmailFormChange.bind(this)}
        className={css['magic-link-form']}
      >
        <h1 className={cx('accent-text-color', css['magic-link-title'], css['slide-up'])}>
          {
            (magicLinkStore.sent && magicLinkStore.status === 200)
              ? t('common:auth.check-email', 'Thank you. Please check your email to sign in.')
              : (defaultTitleOverride || t('common:auth.form', 'Sign in with magic link'))
          }
        </h1>
        {
          subTitle
          && <p className={cx(css['magic-link-subtitle'], css['slide-up'])}>{subTitle}</p>
        }
        <div className={cx(
          'magic-link-field',
          {
            [css['magic-link-field-email']]: true,
            [css['magic-link-field-email-pristine']]:
            this.form.isPristine && !this.form.$('email').touched,
          },
        )}
        >
          <InputTextWithLoader
            id="shippingCouponCode"
            loading={magicLinkStore.sending}
            success={(magicLinkStore.sent && magicLinkStore.status === 200)}
            error={magicLinkStore.status === 404 || magicLinkStore.status === 403}
            customErrorText={magicLinkStore.status !== 200 ? (magicLinkStore.message ? t(`checkout:login.${magicLinkStore.message}`) : t('common:auth.no-account', 'No account matching provided email address.')) : null}
            field={form.$('email')}
          />
        </div>

        {
          (magicLinkStore.sent && magicLinkStore.status === 200)
          || (
            <div className={css['magic-link-form-actions']}>
              <button
                id="login__magicLink"
                className={cx('btn', 'btn-solid-dark', css['magic-link-form-button'])}
                onClick={e => form.onSubmit(e, {
                  onSuccess: this.onSuccess.bind(this),
                })
                }
                disabled={magicLinkStore.sending || form.$('email').error || this.form.isPristine ? 'disabled' : ''}
                type="submit"
              >
                {t('common:auth.email-link', 'Email me the magic link')}
              </button>
            </div>
          )
        }
      </form>
    );
  }
}

export default MagicLinkFormLayout;
